export function validEmail(value){
    const re = /^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/;
    return re.test(String(value).toLowerCase());
}

export function validPhoneNumber(value){
    return value.length == 9;
}

export function validPassword(value){
    var password = /^(?=.*[0-9])(?=.*[!@#$%^&*])[a-zA-Z0-9!@#$%^&*]{7,15}$/;
    return value.match(password);
}

export function hasLength(value) {
    return value.length > 0
}

export function hasValue(value){
    return value != null
}

export function isValidNIF(nif) {
    let isOnlyDigits = /^\d+$/.test(nif)
    return isOnlyDigits && nif.length == 9
}