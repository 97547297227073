import React, { useState, useContext } from "react"
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import { Avatar, IconButton, Typography } from '@material-ui/core';
import cogoToast from 'cogo-toast';
import { AppContext, checkIfUnauthorized } from "../../../context/AppContext";
import "./create.css";
import { baseEndpoint, urlToFile } from "../../../utils/utils"
import { createUser } from "../../../utils/requests/createUser" 
import { validEmail, validPhoneNumber, validPassword, hasLength, hasValue } from  "../../../utils/validator";
import PhotoCamera from "@material-ui/icons/PhotoCamera";
import { useHistory } from "react-router-dom";
const axios = require('axios').default;


function UsersCreate({location}) {
    let history = useHistory()
    const [state, setState] = useContext(AppContext);
    const [name, setName] = useState('')
    const [lastName, setLastName] = useState('')
    const [email, setEmail] = useState('')
    const [phoneNumber, setPhoneNumber] = useState('')
    const [role, setRole] = useState('')
    const [userID, setUserID] = useState('')
    const [department, setDepartment] = useState('')
    const [userProfileImg, setUserImg] = useState(null)
    const [hasError, setError] = useState(false);

    const [nameError, setNameError] = useState(false);
    const [lastNameError, setLastNameError] = useState(false);
    const [phoneError, setPhoneError] = useState(false);
    const [roleError, setRoleError] = useState(false);
    const [departmentError, setDepartmentError] = useState(false);
    const [emailError, setEmailError] = useState(false);
    const [userIDError, setUserIDError] = useState(false);
    const [imageError, setImageError] = useState(false);
    const [fileObj, setFileObj] = useState(null);

    // location
    let isPartner = location.state ? location.state.isPartner : false
   
    function goBack() {
        history.goBack()
    }

    function addUser() {
        var registerError = false
        
        if (!hasLength(name)) {
            setNameError('Insira um nome por favor')
            registerError = true
        } else {
            setNameError(null)
        }

        if (!validEmail(email)) {
            setEmailError("Insira email válido por favor")
            registerError = true
        } else {
            setEmailError(null)
        }

        if (!validPhoneNumber(phoneNumber)) {
            setPhoneError("Insira um número de telémovel válido")
            registerError = true
        } else {
            setPhoneError(null)

        }

        if (!hasLength(role)) {
            setRoleError("Insira um departamento por favor")
            registerError = true
        } else {
            setRoleError(null)
        }

        /*
        if (!hasLength(userID)) {
            setUserIDError("Insira um identificador válido")
            registerError = true
        } else {
            setUserIDError(null)
        }
        */
        if(!hasLength(department)) {
            setDepartmentError("Especifique um departamento")
            registerError = true
        }else {
            setDepartmentError(null)
        }

        if (registerError) {
            setError(true)
            return;
        }

        const formData = new FormData();
        let user = {}
        user.username = email
        user.email = email
        user.firstName = name
        user.lastName = lastName
        //user.userID = userID
        user.role = role
        user.department = department
        user.phoneNumber = phoneNumber
        user.password = 'NULL'
        user.clients = []

        if(isPartner) {
            user.partners = [location.state.partner.id]
            user.clients = location.state.partner.clients.map(x => x.id)
        } else {
            user.clients.push(state.currentClient)
        }

        formData.append('data', JSON.stringify(user))
        if(fileObj)
        {
            formData.append('files.profileImg', urlToFile(userProfileImg, fileObj.name, fileObj.type), fileObj.name)
        }
        
        setState(state => ({ ...state, isLoading: true }))
        createUser(formData, state, (success) => {
            history.goBack()
            setState(state => ({ ...state, isLoading: false }))
            cogoToast.success('Utilizador registado  com sucesso!');
        }, (failure) => {
            console.log(failure)
            setState(state => ({ ...state, isLoading: false }))
            cogoToast.error('Erro ao fazer registo');
        })
    }

    var fileSelectedHandler = (event) => {
        let file = event.target.files[0]
        setFileObj({
            name: file.name,
            type: file.type
        })
        // Check if the file is an image.
        if (file.type && file.type.indexOf('image') === -1) {
            cogoToast.error("Formato inválido!")
            return;
        }


        const reader = new FileReader();
        reader.addEventListener('load', (event) => {
            setUserImg(event.target.result)
        });
        reader.readAsDataURL(file);
    }

    // Text state and helpers
    var initName = ((value => {
        setName(value)
        setError(false)
    }))
    var initLastName = ((value => {
        setLastName(value)
        setError(false)
    }))
    var initEmail = ((value) => {
        setEmail(value)
        setError(false)
    })
    var initPhone = ((value) => {
        setPhoneNumber(value)
        setError(false)
    })
    var initRole = ((value) => {
        setRole(value)
        setError(false)
    })
    var initUserID = ((value) => {
        setUserID(value)
        setError(false)
    })
    var initDepartment = ((value) => {
        setDepartment(value)
        setError(false)
    })


    return (
        <span>
            {
                <>

                    <h2>Dados Pessoais do {isPartner ? "Funcionário" : "Utilizador"}</h2>
                    {/* Partner image  */}
                    {
                        <span className="flexSpan2">
                            {
                                userProfileImg == null ?
                                <span className="flexSpan">
                                    <div className="divPhotoPartner">
                                        <Avatar fontSize="large" className="photoPartner" />
                                    </div>
                                    {
                                        imageError ? <span className="noPhotoPartner">Insira foto de utilizador</span> : <></>
                                    }
                                </span>
                                :
                                <img className="photoPartner" src={userProfileImg}></img>
                            }
                            <input accept="image/*" style={{ display: "none" }} onChange={fileSelectedHandler} id="upload-photo" type="file"></input>
                            <label className="upload-img" htmlFor="upload-photo">
                                <IconButton className="camera-icon-partner" aria-label="upload picture" component="span">
                                    <PhotoCamera/>
                                </IconButton>
                            </label>
                        </span>
                        
                    }

                    { /* Name */}
                    <TextField
                        className="inputField"
                        id="name"
                        label="Nome"
                        variant="outlined"
                        value={name}
                        onInput={e => initName(e.target.value)}
                        error={nameError ? true : null}
                        helperText={nameError ? nameError : null}
                    />

                    { /* LastName */}
                    <TextField
                        className="inputField"
                        id="name"
                        label="Último Nome"
                        variant="outlined"
                        value={lastName}
                        onInput={e => initLastName(e.target.value)}
                        error={lastNameError ? true : null}
                        helperText={lastNameError ? lastNameError : null}
                    />

                    { /* Email */}
                    <TextField
                        className="inputField"
                        id="email"
                        label="Email"
                        variant="outlined"
                        value={email}
                        onInput={e => initEmail(e.target.value)}
                        error={emailError ? true : null}
                        helperText={emailError ? emailError : null} />

                    { /* PhoneNumber */}
                    <TextField
                        className="inputField"
                        id="phone"
                        label="Número de Telemóvel"
                        variant="outlined"
                        value={phoneNumber}
                        onInput={e => initPhone(e.target.value)}
                        error={phoneError ? true : null}
                        helperText={phoneError ? phoneError : null} />

                    { /* Role */}
                    <TextField
                        className="inputField"
                        id="role"
                        label="Cargo"
                        variant="outlined"
                        value={role}
                        onInput={e => initRole(e.target.value)}
                        error={roleError ? true : null}
                        helperText={roleError ? roleError : null} />

                    { /* UserID */}
                    {/*
                    
                    
                    
                    <TextField
                        className="inputField"
                        id="userID"
                        label="UserID"
                        type="number"
                        pattern="[0-9]*"
                        variant="outlined"
                        value={userID}
                        onInput={e => initUserID(e.target.value)}
                        error={userIDError ? true : null}
                        helperText={userIDError ? userIDError : null} />
                    */}
                    { /* Department */}
                    <TextField
                        className="inputField"
                        id="department"
                        label="Departamento"
                        variant="outlined"
                        value={department}
                        onInput={e => initDepartment(e.target.value)}
                        error={departmentError ? true : null}
                        helperText={departmentError ? departmentError : null} />
                    {
                        <div style={{marginBottom: "20px"}}>
                            <Button className="simple-button" onClick={goBack}>Voltar</Button>
                            <Button style={{ float: "right" }} className="simple-button" onClick={addUser}>Adicionar {isPartner ? "Funcionário" : "Utilizador"}</Button>
                        </div>
                    }
                </>
            }
        </span>
    )
}


export default UsersCreate