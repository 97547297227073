/**
 * Layout component that query some menu holders
 */
import React, {useState, useContext, useEffect} from "react"
import '../../css/variables.css'
import "./side-menu.css"
import logo from "../../static/Logo.png"
import arrow_down from "../../static/arrow_down.png"
import arrow_up from "../../static/arrow_up.png"
import ProfileItem from "../../components/menu/profile-item/profile-item"
import '../../components/entityindicators/indicator.css'
import { AppContext  } from "../../context/AppContext"
import CloseIcon from '@material-ui/icons/Close';
import LogoutItem from "./logout-item/logout-item"
import Constants from '../../constants';
import { CircularProgress, IconButton, Paper, Popper } from "@material-ui/core"
import Skeleton from "@material-ui/lab/Skeleton"
import { useHistory } from "react-router-dom";
import { baseEndpoint } from "../../utils/utils"

function SideMenu({isLoading, clientData, menuEntries, menuOpen, toggleMenu, logoutUser, isMobile}) {
    const [state, setState] = useContext(AppContext);
    const [menuUpdated, setMenuUpdated] = useState(false);
    let showInitiallySubmenu = (() => {
        let showISubM = false
        if(menuEntries) {
            menuEntries.forEach(menu => {
                if(menu.isSelected) { showISubM = true }
            })
        }
        return showISubM
    })()
    
    const[showSubMenu, setShowSubMenu] = useState(showInitiallySubmenu);
    if(menuEntries && !showSubMenu && !menuUpdated) {
        setMenuUpdated(true)
        setShowSubMenu(showInitiallySubmenu)
    }
    let hasMoreClients = state.currentUser.clients.length > 1
    const [anchorEl, setAnchorEl] = React.useState(null);
    const showDrop = Boolean(anchorEl);
    const id = showDrop ? 'simple-popover' : undefined;
    let isMobileAndOpen = isMobile && menuOpen
    const menuClass = isMobileAndOpen ? ' mobileMenu': '';

    let history = useHistory();
    
    const handleClose = () => {
        setAnchorEl(null);
    };

    function selectClient(client) {
        let stringClient = JSON.stringify(client)
        localStorage.setItem(Constants.lastClient, stringClient)
        setState({ ...state, currentClient: client })
        handleClose()
    }

    function showClients(event) {
        setAnchorEl(Boolean(anchorEl) ? null : event.currentTarget);
    }
   
    // Menu
    function selectMenu(menuHolder) {
        if (menuHolder.hasSubmenus) {
            setShowSubMenu(!showSubMenu)
        }else {
            setShowSubMenu(false)
            history.push("/"+menuHolder.path);
            toggleMenu()
        }
    }

    function selectSubmenu(holder){
        history.push("/"+holder.path);
        toggleMenu()
        setShowSubMenu(true);
    }

    function showProfile(e) {
        e.preventDefault();
        history.push('/profile');
        toggleMenu()
        setShowSubMenu(false)
    }

    function showMenuHolders() {
        return (
            <div className={'holders'}>
                {
                    menuEntries.map(menu => (
                        <div key={menu.id} className="menu-holder">
                            <a className={menu.isSelected ? "menu-selected" : "menu-not-selected"} 
                            style={{cursor: "pointer", display: "inline-flex", marginBottom: menu.hasSubmenus ? '0px' : '10px'}} onClick={() => selectMenu(menu)}>
                                
                                <div className={menu.isSelected ? "border-show" : "border"}/>
                                <img className="icon" src={menu.image}></img>
                                <span className="title" style={{ color: "white" }} >{menu.title}</span>
                                {
                                    menu.hasSubmenus ? <img id="arrow-img" src= {menu.isSelected ? arrow_up : arrow_down}></img> : <img></img>
                                } 
                            </a>
                            {
                                menu.submenus.map(submenu => (
                                    <div key={submenu.id} className={showSubMenu ? "submenu-div-show" : "submenu-div"}>
                                        <a style={{cursor: "pointer"}} className={submenu.isSelected ? "submenu-selected" : "submenu-title " } onClick={() => selectSubmenu(submenu)}>{submenu.title}</a>
                                    </div>
                                ))
                            }
                        </div>
                    ))
                }
            </div>
        )
    }
    
    function showCurrentClient() {
        let currentLogo = null;
        clientData.forEach( node => {
            if(Number(node.id) === state.currentClient.id) {
                currentLogo = baseEndpoint+node.logo.url
            }
        })
        if(currentLogo == null) {
            currentLogo = logo
        }
        if(state.currentClient != null) {
            return (
                <a>
                    <div className="sideMenuHeader">
                        <img className="headerImg" src={currentLogo}></img>
                        <span onClick={hasMoreClients? showClients : null } style = {{ display:  'flex', cursor: "pointer" }}>
                            <span className="titleHeader">{state.currentClient.name}</span>
                            {
                                hasMoreClients ?
                                <img className={showDrop ? "rotate180 arrow-img" : "arrow-img"} src= {showDrop ? arrow_down : arrow_down}></img>
                                : <></>
                            }       

                                  
                        </span>
                        {
                            isMobileAndOpen ? 
                            <IconButton className="closeMobileMenuBtn" onClick={toggleMenu}> <CloseIcon/> </IconButton>   
                            :
                            <></>
                        } 
                    </div>

                    {
                       hasMoreClients? 
                       <Popper
                            id={id}
                            anchorEl={anchorEl}
                            open={showDrop}
                            onClose={handleClose}
                            placement="bottom-start"
                            disablePortal={true}
                            modifiers={{
                            flip: {
                                enabled: true,
                            },
                            preventOverflow: {
                                enabled: true,
                                boundariesElement: 'scrollParent',
                            },
                            }}>
                                <Paper>
                                    {
                                        state.currentUser.clients.map(client => (
                                            <div key={client.id} className = 'option-item' onClick={ (() => selectClient(client))}>
                                                <span className="option-title">{client.name}</span>
                                            </div>
                                        ))
                                    } 
                                </Paper>
                        </Popper>
                        : <></>
                    }
                    
                </a>
            )
        } else {
            return (<></>)
        }
    }
    
    useEffect(() => {
        if (anchorEl) {
          document.addEventListener("mousedown", handleClick)
        } else {
          document.removeEventListener("mousedown", handleClick)
        }
       // Specify how to clean up after this effect:
       return function cleanup() {
          document.removeEventListener("mousedown", handleClick)
       }
    }, [anchorEl])
    
    const handleClick = event => {
        if (!document.getElementById(id).contains(event.target)) {
           setAnchorEl(null)
           //setCustomOpen(false)
        }
    }

    
    return (
        <>
            <div className={'side-menu' + menuClass}>  
                {
                    isLoading || !clientData ? 
                    <span>
                        {
                            Array.from(Array(5).keys()).map(item => {
                                return (
                                    <Skeleton key={item} className="skeletonMenu" variant="text" />
                                )
                            })
                        }
                    </span>
                    
                    : 
                    <>
                        { showCurrentClient() }
                        { showMenuHolders() }
                        {
                            menuOpen ?
                            <></>
                            :
                            <div id="vertical-spacer"/>
                        }
                        <span onClick={showProfile}><ProfileItem /></span>
                        {
                            isMobileAndOpen ?
                            <span onClick={logoutUser}><LogoutItem /></span>
                            :
                            <></>
                        }
                        <span className="buildVersion">v0.0.2</span>
                    </>
                }
            </div>
        </>
    )
}
export default SideMenu